<template>
  <div>
    <Header></Header>
    <Body></Body>
    <Body></Body>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Body from '@/components/HelloWorld.vue'

export default {
  components: {
    Header,
    Body
  }
}
</script>
